var jQuery = require('jquery');
require('jquery.easing');
window.jQuery = jQuery;
window.$ = jQuery;
// require('popper.js');

// require('bootstrap-five/js/dist/carousel');
require('bootstrap-five/js/dist/collapse');
// require('bootstrap-five/js/dist/accordion');
require('bootstrap-five/js/dist/dropdown');
require('bootstrap-five/js/dist/modal');
require('bootstrap-five/js/dist/tab');

// require('bootstrap-select');

// require('owl.carousel');
// require('masonry-layout');
// require('pc-bootstrap4-datetimepicker');
require('magnific-popup');

const feather = require('feather-icons')

// require('@fortawesome/fontawesome-free');

require('mmenu-js');
// require('@iconify/iconify');

$(function() {

    //feather icons
   feather.replace()

//    $('.selectpicker').selectpicker();

    // $(window).on("load resize",function(e){

    // });

    $('.popup-image').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        },
    });


    //resets content padding around images
    $( ".copy--inset img" ).parent().css( "margin-left", "0 0 2rem 0");


    // Universal Analytics event tracking
    if (typeof ga !== 'undefined') {

        // track all phone number click events
        $('a[href^="tel:"]').on('click', function(e) {
            var href = $(this).attr('href');
            var value = href.split(':');

            // console.log('clicked', $(this).text(), value[1]);
            ga('send', 'event', 'Phone', 'click', value[1]);
        });

        // track all email click events
        $('a[href^="mailto:"]').on('click', function(e) {

            var href = $(this).attr('href');
            var value = href.split(':');

            // console.log('clicked', $(this).text(), value[1]);
            ga('send', 'event', 'Email', 'click', value[1]);
        });

    }

    $('.toggle-icon').on('click', function() {
        $(this).find('.material-icons').toggleClass('d-none');
    });



    initWindowResize()
});


//-----------------------------------------------
// Global AJAX / Application Functions
//-----------------------------------------------

document.addEventListener(
    "DOMContentLoaded", () => {
        new Mmenu( "#mmenu", {
            "extensions": [
                "theme-light", "position-front", "position-left"
            ]
         });
    }
);

$('.news-tab button[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
    let category = $(e.target).data('category');
    let url =  window.location.href;
    let spliturl = url.split('/');

    let newurl;
    if (spliturl[spliturl.length - 1] == 'latest') {
        newurl = spliturl.join('/');
    } else {
        newurl = spliturl.slice(0,-1).join('/');
    }

    if (category != 'all') {
        newurl += '/' + category;
    }
    window.history.pushState({}, '', newurl);
});


function initWindowResize() {

    $(window).on('resize load', function() {
        var windowWidth = $(window).width();

        if (windowWidth > 768) {

            var headerHeight = $("#header-imgs").outerHeight() + $("#sticky-nav").outerHeight() ;

            var windowHeight = $(window).height();

            var containerHeight = windowHeight - headerHeight

            $("#full-container").css({
                "height": containerHeight,
            });

        }else{
            $("#full-container").css({
                "height": 'auto',
            });
        }
    });
}
